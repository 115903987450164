import './bootstrap';
import '../css/app.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import ReactGA from 'react-ga4';

import * as Sentry from "@sentry/react";

const appName =
	window.document.getElementsByTagName('title')[0]
		?.innerText || 'OnlyRacing';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) =>
		resolvePageComponent(
			`./Pages/${name}.jsx`,
			import.meta.glob('./Pages/**/*.jsx')
		),
	setup({ el, App, props }) {
		Sentry.init({
			environment: import.meta.env.MODE,
			dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
			integrations: [
				Sentry.feedbackIntegration({
					colorScheme: "dark",
					showBranding: false,
				})
			],
		});
		ReactGA.initialize('G-6EZYS4ZP8D');
		const root = createRoot(el);
		root.render(<App {...props} />);
	},
	progress: {
		delay: 250,
		color: 'hsl(var(--p))',
		includeCSS: true,
		showSpinner: false
	}
});
